
.notification_modal,
.notification_overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999;
}

.notification_overlay {
    background: rgba(49, 49, 49, 0.8);
}

.notification_content {
    position: absolute;
    top: 50%;
    /* left: 85%; */
    right: 0;
    transform: translate(0%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 20px;
    border-radius: 3px;
    max-width: 600px;
    height: 100vh;
    z-index: 9999;
    overflow-y: auto;
}

body.drakmode .notification_content {
    background: #066DE6;
}

.notification_content::-webkit-scrollbar {
    display: none;
}


.notification_details h3 {
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding-bottom: 5px;

}

.notification_details p {
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    opacity: 0.75;
    padding-bottom: 5px;
}

.notification_details h5 {
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.notification_all_flx {
    display: flex;
    justify-content: start;
    gap: 20px;
    padding-top: 10px;
    padding-bottom: 5px;
}

/* .notificationmodal_main ::-webkit-scrollbar {
    display: none;
} */

.notification_all_main {
    padding: 17px;
    /* overflow: auto;
    height: 400px; */
}

p.notification_txt {
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding: 5px;
    padding: 0 10px;
}

.notificationmodal_main .closemark {
    position: absolute;
    z-index: 99;
    top: 10px;
    right: 33px;
    cursor: pointer;
}



@media (max-width: 767px) {

    .SubscriptModal_body {
       width: 95%;
    }

    .SubscriptModal_body .content_wrap {
        width: 100%;
        height: auto;
        max-height: 380px;
        overflow-y: auto;
    }

    .paymaent_wrapbtn .Cancel_btn {
        max-width: 100%;
    }

    .paymaent_wrapbtn .pay_btn {
        max-width: 100%;
    }
}