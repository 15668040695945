 /* .selectClndrDiv .react-calendar__tile--active {
    background-color: #FF9C00 !important;
} */

/* .selectClndrDiv .react-calendar {
    width: 100%;
} */
/* 
.selectClndrDiv {
    margin: 25px 0;
} */
/* 
.react-calendar__tile--now {
    background: transparent;
} */

/* .react-calendar__tile--now:hover {
    background: #ccc;

}  */

.bookAppHead {
  font-size: 33px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(126, 115, 115, 0.671);
  margin: 0 0 30px 0;
}

.immiLogoFig {
  width: 55px;
  height: 50px;
  margin: 0;
}

.immiLogoFig img {
  width: 100%;
  height: 100%;
}

.immiHead {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  padding-left: 10px;
  text-transform: uppercase;
}

.immiMainDiv {
  display: flex;
  align-items: center;
}

.immiSignBtn {
  background-color: transparent;
  border: 0;
  color: #ff9c00;
  font-size: 17px;
  font-weight: 600;
}

.immiSignBtn:hover {
  text-decoration: underline;
}

.immiAllDivsMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid rgba(126, 115, 115, 0.671);
  margin-bottom: 20px;
}

.bookAppoSection {
  width: 100%;
  height: auto;
  padding-top: 95px;
  margin-bottom: 50px;
}

.slsvUl {
  padding: 0;
}

.slsvLi {
  list-style-type: none;
  font-size: 16px;
  font-weight: 700;
  color: #adb1b5;
}

.slsvLiActive {
  color: #005ad9;
  text-decoration: underline;
}

.seleSerText {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 20px 0;
}

.slectAftTab .react-tabs__tab {
  text-transform: uppercase;
  color: #0000008c;
  font-weight: 700;
  font-size: 16px;
  margin-right: 15px;
}

.slectAftTab .react-tabs__tab--selected {
  border: 0;
  color: #ff9c00;
}

.slectAftTab .react-tabs__tab--selected::after {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: #ff9c00;
  position: absolute;
  left: 0;
  bottom: 0;
}

.slectAftTab .react-tabs__tab:focus:after {
  height: 4px;
  left: 0;
  bottom: 0;
  right: auto;
  background: #ff9c00 !important;
}

.slectAftTab .react-tabs__tab-list {
  border: 0;
}

.tablistDiv {
  border-bottom: 1px solid #d9d9d9;
}

.slecHead {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 15px 0;
}

.slecTabPanDiv {
  padding: 20px 0 7px 0;
}

.phmAptLink:hover {
  text-decoration: none;
}

.phnAppText {
  font-size: 17px;
  font-weight: 700;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  color: #000;
}

.phnAppDoll {
  font-size: 17px;
  font-weight: 500;
  margin-right: 10px;
  color: #000;
}

.phnMoreInfoBtn {
  background-color: transparent;
  border: 0;
  font-size: 16px;
  font-weight: 700;
  color: #ff9c00;
}

.phmAptDiv {
  padding: 25px 30px;
  border: 1px solid #a096968c;
  border-radius: 5px;
  transition-property: all;
  transition-duration: 200ms;
  cursor: pointer;
}

.phmAptDiv:hover {
  border-color: #ff9c00;
}

.sbmybtn {
  border: none;
  background-color: #ff9c00;
  padding: 5px 15px;
  border-radius: 5px;
  /* display: flex; */
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
}

.avaiPrefTextDiv .sbmtrt {
  display: block;
}

.avaiPrefMainDiv .sbmtrt {
  display: none;
}

.boxHover {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #a096968c;
  background-color: #fff;
  transition-property: all;
  transition-duration: 300ms;
}

.boxHover:hover {
  background-color: #ff9d008a;
}

.phnAppTextDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.serveDeepText {
  font-size: 15px;
  font-weight: 700;
  margin-right: 8px;
}

.serveEditLink {
  font-size: 13px;
  font-weight: 700;
  color: #005ad9;
}

.serveEditLink:hover {
  color: #005ad9;
}

.serveDeepDiv {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.phnApnDiv {
  display: inline-block;
  padding: 16px 22px;
  background-color: #f7f7f7;
  border-radius: 7px;
}

.phnTwinty {
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 2px 0;
}

.twityText {
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.serveDeepMainDiv {
  margin-bottom: 15px;
}

.slYuHead {
  font-size: 23px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.selectClndrDiv .react-calendar {
  width: 100%;
}

.selectClndrDiv {
  margin: 25px 0;
}

.aviMonHead {
  font-size: 17px;
  font-weight: 700;
  margin: 0 0 7px 0;
}

.youCnSchl {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.youCnSchl span {
  color: #ff9c00;
  font-weight: 700;
  cursor: pointer;
}

.youCnSchl span:hover {
  text-decoration: underline;
}

.aviYouDiv {
  margin-bottom: 20px;
}

.sevTimeAm {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.sevTimeAmBlock {
  display: flex;
  align-items: center;
  padding: 0px 11px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.sevTimeAm span {
  font-size: 16px;
  font-weight: 600;
  color: #525050;
}

.sevTimeAmColor {
  background-color: #ff9d00ef;
}

.sevTimeAmColor:hover {
  background-color: #ff9c00;
}

.react-calendar__tile--now {
  background: none !important;
  /* background:#fff; */
}

/* .selectClndrDiv .react-calendar__tile--now {
    background-color: #FF9C00 !important;
} */

/* .selectClndrDiv .react-calendar__tile--active:enabled:hover {
    background-color: #ff9d00ef !important;
} */

.sevTimeAmColor span {
  color: #fff;
}

.sevTimeAmDiv {
  display: flex;
  flex-wrap: wrap;
  /* overflow-x: auto; */
  row-gap: 10px;
  padding-bottom: 7px;
}

.sevTimeAmDiv::-webkit-scrollbar {
  height: 3px;
  width: 3px;
  border-radius: 5px;
}

.sevTimeAmDiv::-webkit-scrollbar-thumb {
  background: #19313e;
}

.sevMorHead {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.timeScheduleDiv {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(126, 115, 115, 0.671);
}

.sevTimeAmSelectColor {
  background-color: #ff9d0048;
  border: 1px solid #ff9c00;
  pointer-events: none;
  cursor: not-allowed;
}

.sevjsfyjsefbj {
  background-color: #ff9d0048;
  border: 1px solid #ff9c00;
}

.sevTimeAmSelectColor span {
  color: #525050;
}

.sevTimeAmSelectColor i {
  font-size: 12px;
  margin-top: 3px;
  margin-right: 4px;
  color: #ff9c00;
}

.joinWaitLink {
  display: inline-block;
  background-color: #ff9d00ef;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;
}

.joinWaitLink:hover {
  color: #fff;
  text-decoration: none;
  background-color: #ff9c00;
}

.serMDiv {
  margin-bottom: 15px;
}

.predateSection {
  padding-top: 95px;
  margin-bottom: 50px;
}

.selectClndrDiv .react-calendar__tile--active {
  background-color: #ff9c00 !important;
}

/* Join Waitlist Section */
.joinWaitListSection {
  padding-top: 95px;
  margin-bottom: 50px;
}

.joinWaitHead {
  font-size: 23px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 4px 0;
}

.weLLText {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  margin: 0;
}

.joinWaitDiv {
  margin-bottom: 15px;
}

.avaiPrefText {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 0;
}

.avaiPrefTextDiv {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectDtDiv {
  border: 1px solid #ff9c00;
  border-radius: 7px;
  padding: 12px 15px;
  margin-bottom: 12px;
}

.dtTiSelecText {
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 15px;
  margin: 0 0 15px 0;
  border-bottom: 1px solid rgba(126, 115, 115, 0.671);
}

.dtTiSelecText i {
  margin-right: 13px;
  color: rgba(126, 115, 115, 0.445);
}

.dtDateHead {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 8px 0;
}

.dtDateHeadSpan {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.473);
  margin-left: 10px;
}

.dateListDiv .react-tabs__tab-list {
  border-bottom: 0;
  display: flex;
  gap: 0.7%;
  margin-bottom: 20px;
}

.dateListDiv .react-tabs__tab {
  padding: 6px 15px;
  font-size: 16px;
  background-color: #f7f7f7;
  border-radius: 25px;
}

.dateListDiv .react-tabs__tab--selected {
  border: 1px solid #ff9c00 !important;
  /* background-color: #ff9d0015;
    font-weight: 500; */
}

.dateListDiv .react-tabs__tab:focus:after {
  background-color: transparent !important;
}

.tabRangeInp {
  width: 48%;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #42404073;
  outline: none;
}

.tabRngeDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dtDateMainDiv {
  margin-bottom: 15px;
}

.dtTimeMainDiv {
  margin-bottom: 25px;
}

.tabTimeLeftDiv {
  width: 18%;
}

.tabTimeLeftDiv select {
  width: 100%;
  border: 1px solid #42404063;
  padding: 6px;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
}

.tabTimeRightDiv {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabTimeRightDiv input {
  width: 48%;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #42404073;
  outline: none;
}

.savePrefBtn {
  width: 100%;
  padding: 10px 0;
  border: 0;
  background-color: rgba(255, 157, 0, 0.904);
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  border-radius: 5px;
}

.savePrefBtn:hover {
  background-color: #ff9c00;
}

.addPrefBtn {
  border: 0;
  background-color: transparent;
  font-size: 15px;
  font-weight: 700;
  color: #ff9d00bd;
}

.avaiPrefMainDiv {
  margin-bottom: 25px;
}

.aviEditLink {
  font-size: 17px;
  font-weight: 700;
  color: #ff9c00;
}

.aviEditLink:hover {
  color: #ff9c00;
  text-decoration: none;
}

.phnAppsMainDiv {
  border: 1px solid #42404073;
  border-radius: 7px;
  padding: 12px 15px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.phnApSpanInDiv {
  display: flex;
  align-items: center;
}

.phnAppoText {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.phnAppoSecText {
  font-size: 15px;
  font-weight: 400;
  color: #000000bb;
}

.phnAppoTextsDiv {
  padding-left: 15px;
}

.phnAppIcon {
  font-size: 16px;
  color: rgba(136, 129, 129, 0.589);
}

.howFormHlfInp {
  width: 49.5%;
  padding: 8px 7px;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.363);
  border-radius: 4px;
  outline: none;
  transition-property: all;
  transition-duration: 150ms;
}

.howFormHlfInp:focus {
  border: 1px solid #000;
}

.howFormInpDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.howFormHlfInpInDiv {
  width: 49.5%;
  display: flex;
  align-items: center;
}

.howFormInSpan {
  width: 10%;
  display: inline-block;
  border-top: 1px solid rgba(0, 0, 0, 0.363);
  border-left: 1px solid rgba(0, 0, 0, 0.363);
  border-bottom: 1px solid rgba(0, 0, 0, 0.363);
  padding: 8px 0;
  text-align: center;
  border-radius: 4px 0 0 4px;
}

.howFormInInp {
  width: 90%;
  padding: 8px 7px;
  outline: none;
  border-radius: 0 4px 4px 0;
  border: 1px solid rgba(0, 0, 0, 0.363);
  transition-property: all;
  transition-duration: 150ms;
}

.howFormInInp:focus {
  border: 1px solid #000;
}

.howFormInInp::-webkit-inner-spin-button,
.howFormInInp::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.howFormFullInp {
  width: 100%;
  padding: 8px 7px;
  font-size: 17px;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.363);
  border-radius: 4px;
  outline: none;
  resize: none;
  transition-property: all;
  transition-duration: 150ms;
}

.howFormFullInp:focus {
  border: 1px solid #000;
}

.upnText {
  font-size: 16px;
  font-weight: 400;
  color: #242222;
  margin: 0 0 20px 0;
}

.upnText span {
  text-transform: uppercase;
}

.joinWtListBtn {
  width: 100%;
  padding: 10px 0;
  border: 0;
  background-color: #ff9c00;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  margin-bottom: 20px;
}

.upnText a {
  font-weight: 700;
  color: #ff9c00;
}

.upnText a:hover {
  color: #ff9c00;
}

.backLinkDiv {
  border-top: 1px solid rgba(126, 115, 115, 0.671);
  padding-top: 12px;
}

.backLinkAn {
  display: inline-block;
  width: 120px;
  padding: 8px;
  border: 1px solid #ff9c00;
  color: #ff9c00;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
}

.backLinkAn:hover {
  color: #ff9c00;
  text-decoration: none;
}

@media (max-width: 991px) {
  .bookAppHead {
    font-size: 28px;
    padding-bottom: 12px;
    margin: 0 0 20px 0;
  }

  .sevTimeAmDiv::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 767px) {
  .phnApnDiv {
    display: block;
  }

  .howFormInInp {
    width: 84%;
  }

  .howFormInSpan {
    width: 16%;
  }
}

@media (max-width: 575px) {
  .seleSerText {
    font-size: 20px;
    margin: 0 0 3px 0;
  }

  .slecTabPanDiv {
    padding: 10px 0 7px 0;
  }

  .slecHead {
    font-size: 17px;
    margin: 0 0 7px 0;
  }

  .bookAppHead {
    font-size: 22px;
    padding-bottom: 8px;
    margin: 0 0 14px 0;
  }

  .immiAllDivsMain {
    padding: 0 10px 5px 10px;
  }

  .immiHead {
    font-size: 13px;
  }

  .immiSignBtn {
    font-size: 15px;
  }
}

@media (max-width: 479px) {
  .bookAppHead {
    font-size: 18px;
    margin: 0 0 5px 0;
  }

  .immiHead {
    font-size: 11px;
    padding-left: 3px;
  }

  .immiLogoFig {
    width: 45px;
    height: 45px;
  }

  .immiSignBtn {
    font-size: 13px;
  }

  .slYuHead {
    font-size: 17px;
    margin: 0 0 7px 0;
  }

  .howFormInpDiv {
    display: block;
  }

  .howFormHlfInp {
    width: 100%;
  }

  .howFormHlfInpInDiv {
    width: 100%;
  }

  .howFormHlfInpMarge {
    margin-top: 10px;
  }

  .weLLText {
    font-size: 16px;
  }

  .joinWaitHead {
    font-size: 20px;
    margin: 0;
  }

  .dtTiSelecText {
    padding-bottom: 8px;
    margin: 0 0 10px 0;
  }

  .dateListDiv .react-tabs__tab {
    font-size: 12px;
  }

  .dtDateHead {
    font-size: 15px;
  }

  .dateListDiv .react-tabs__tab-list {
    gap: 3%;
  }

  .tabRangeInp {
    font-size: 12px;
  }

  .tabTimeRightDiv input {
    font-size: 12px;
  }

  .tabTimeLeftDiv select {
    font-size: 12px;
  }

  .dtTimeMainDiv {
    margin-bottom: 15px;
  }

  .avaiPrefText {
    font-size: 17px;
  }

  .aviEditLink {
    font-size: 16px;
  }

  .phnAppoText {
    font-size: 12px;
  }

  .phnAppoSecText {
    font-size: 12px;
  }

  .twinMinText {
    font-size: 10px;
  }
}

.overlay-container {
  position: relative;
  display: inline-block;
}

.overlay-container.disabled::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  /* Adjust the overlay color and opacity as needed */
  z-index: 1;
}

.disabled-time {
  position: relative;
  z-index: 2;
}

.disableDiv {
  pointer-events: none;
  /* opacity: 0.7; */
  margin-left: 5px;
  /* background-color: #f4e6cff4; */
}

/* 
  .isBooked{
    color: red;
  }
  */

.btnhover {
  color: #cc3300;
  font-weight: bold;
  padding: 5px;
  border: 1px solid #cc3300;
  border-radius: 5px;
  transition: 0.5s;
}

.btnhover:hover {
  color: #800000;
  border-color: #7c0a02;
}

.joinWaitLinked {
  display: inline-block;
  background-color: #800000;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;
  opacity: 0.8;
  transition: 0.4s;
}

.joinWaitLinked:hover {
  opacity: 1;
}

.modal-header .btn-close {
  background-color: #9999;
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y))
    calc(-0.5 * var(--bs-modal-header-padding-x))
    calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
  transition: 0.4s;
}

.modal-header .btn-close:hover {
  font-size: 18px;
}

.export-csv-button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.export-csv-button:hover {
  text-decoration: none;
}
.dwdtxtflxdiv {
  /* background-color: #4caf50; */
  border: 1px solid #ff9c00;
  color: #ff9c00;
  padding: 11px 36px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 16px;
  /* margin: 4px 2px; */
  cursor: pointer;
  border-radius: 4px;
  transition: 0.4s;
}
.dwdtxtflxdiv:hover{
  background-color: #ff9c00;
  color: #fff;
  text-decoration: none;

}
.dwdtxtflxdiv i {
  margin-left: 10px;
}
.dwdtxtflxdiv h5 {
  margin: 0px;
  font-size: 14px;
}
.strttxt {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #000;
  margin-bottom: 7px;
}
.strtdivmain {
  width: 100%;
  max-width: 30%;
}
.strtdivmain input {
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid #000;
}
.tabRngeDivmain {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
