/* .backLinkDiv {
    border-top: 1px solid rgba(126, 115, 115, 0.671);
    padding-top: 12px;
  } */
  
  .backLinkAn {
    display: inline-block;
    width: 120px;
    padding: 8px;
    border: 1px solid #ff9c00;
    color: #ff9c00;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;

  }
  
  .backLinkAn:hover {
    color: #ff9c00;
  }