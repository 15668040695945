.rmvBtn {
    background-color: #9b111e;
    color: #fff;
    border: 1px solid red;
    width: 23px;
    font-size: 14px;
    border-radius: 6px; 
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
    transform: translate(19em, 3em);
    transition: background-color 0.3s ease;
}
.rmvBtn:hover{
   color: #fff;
   font-weight: bold;
   background-color: #FF0000
}
.RmvBtn{
    background-color: #9b111e;
    color: #fff;
    border: 1px solid red;
    width: 23px;
    font-size: 14px;
    border-radius: 6px; 
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
    transform: translate(19em, 2.5em);
    transition: background-color 0.3s ease;
}

.RmvBtn:hover{
    color: #fff;
    font-weight: bold;
    background-color: #FF0000
}



.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table td,
  .table th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  hr {
    border: 1px solid #ddd;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .highlight{
    background-color: #ffffcc;
  }

  .highlighted-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .highlighted-table th, .highlighted-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }